.body {
  position: relative;
  display: block;
  color: #000000;
  text-align: center;
}

.title {
  margin: 0 0 25px 0;
  padding: 0;
  font-size: 24px;
  line-height: 32px;
}
