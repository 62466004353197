$border-radius: 6px;

$zindex-publisher-preview: 1;
$zindex-video: 1;
$zindex-spinner: 2;
$zindex-doors: 3;
$zindex-buttons: 4;
$zindex-filters: 5;
$zindex-menu: 6;
$zindex-page-top-layer: 50;
$zindex-modal-overlay: 100;
$zindex-toast: 900006;
