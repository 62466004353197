@import-normalize;

:root,
::before,
::after {
  --color-gray-200: #e0e0e0;
  --color-gray-400: #c0c0c0;
  --color-gray-500: #aaaaaa;
  --color-gray-600: #999999;
  --color-gray-700: #888888;
  --color-gray-800: #777777;
  --color-gray-1000: #333333;
  --color-green: #07e66e;
  --color-primary-hover: #54bef9;
  --color-primary-outlined: #000000;
  --color-primary-outlined-background: #54bef9;
  --color-primary-text: #000000;
  --color-primary-solid-text: #ffffff;
  --color-primary: #54bef9;
  --color-red: #e46f61;
  --color-secondary-hover: #ffffff;
  --color-secondary-outlined: #000000;
  --color-secondary-text: #000000;
  --color-secondary: #ffffff;
  --color-tertiary-hover: #dcabcf;
  --color-tertiary-outlined: #000000;
  --color-tertiary-outlined-background: #dcabcf;
  --color-tertiary-text: #000000;
  --color-tertiary-solid-text: #ffffff;
  --color-tertiary: #dcabcf;

  --font-family: SF Pro Display, -apple-system, BlinkMacSystemFont,
    helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial, sans-serif;

  --font-size-large: 22px;
  --font-size-medium: 16px;
  --font-size-small: 12px;

  --gap-large: 16px;
  --gap-medium: 12px;
  --gap-small: 8px;

  --height-large: 60px;
  --height-medium: 40px;
  --height-small: 32px;

  --padding-large: 32px;
  --padding-medium: 16px;
  --padding-small: 12px;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-size: 16px;
  font-family: var(--font-family);
  line-height: 1.5;
  background-image: url(/assets/eyes-bg-overlay.png);
  background-repeat: repeat;
  background-position: top center;
}
