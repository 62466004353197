@import "src/style/vars";
@import "src/style/mixin/responsive";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;

  &.centerVertically {
    justify-content: center;
  }

  &.topLayer {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $zindex-page-top-layer;
  }

  .content {
    width: 100%;
  }
  @include from-m {
    padding: 24px;
  }
}
