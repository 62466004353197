@mixin from-xs() {
  @media only screen and (min-width: 576px) {
    @content;
  }
}

@mixin from-s() {
  @media only screen and (min-width: 768px) {
    @content;
  }
}

@mixin from-m() {
  @media only screen and (min-width: 1024px) {
    @content;
  }
}

@mixin from-l() {
  @media only screen and (min-width: 1280px) {
    @content;
  }
}

@mixin from-xl() {
  @media only screen and (min-width: 1600px) {
    @content;
  }
}

@mixin to-xs() {
  @media only screen and (max-width: 575px) {
    @content;
  }
}

@mixin to-s() {
  @media only screen and (max-width: 767px) {
    @content;
  }
}

@mixin to-m() {
  @media only screen and (max-width: 1023px) {
    @content;
  }
}

@mixin to-l() {
  @media only screen and (max-width: 1279px) {
    @content;
  }
}

@mixin to-xl() {
  @media only screen and (max-width: 1599px) {
    @content;
  }
}
